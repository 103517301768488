//引入vue和下载的router
import Vue from 'vue'
import Router from 'vue-router'
//引入components
import index from '@/components/index'
import login from "@/components/publicComponent/login";
import blogDetailPanel from "../components/publicComponent/blogDetailPanel/blogDetailPanel";
import writeBlogPanel from "../components/publicComponent/createBlog/writeBlogPanel";
import userCenterPanel from "../components/publicComponent/userCenter/userCenterPanel";
import regist from "../components/publicComponent/regist";
import basePanelSearchPanel from "../components/publicComponent/basePanelSearch/basePanelSearchPanel";
import socketChatPanel from "../components/publicComponent/socketChat/socketChatPanel";
import searchUserPanel from "../components/publicComponent/searchUser/searchUserPanel"
import blowUpPlanePanel from "../components/publicComponent/gameCenter/blowUpPlane/blowUpPlanePanel";
import gameCenterPanel from "../components/publicComponent/gameCenter/gameCenterPanel";
import minesweeperPanel from "../components/publicComponent/gameCenter/minesweeper/minesweeperPanel";
import pokerDdzPanel from "../components/publicComponent/gameCenter/pokerDdz/pokerDdzPanel";
import sarsCovPanel from "../components/publicComponent/sarsCov/sarsCovPanel";

//使用router
Vue.use(Router)

//开始配置路由
export default new Router({
    routes: [
        {
            path : '/',
            name : 'index',
            redirect:'/index'
        },{
            path : '/index',  //到时候地址栏会显示的路径
            name : 'index',// 网站头部标题--title
            meta:{title:'万彻小站'},
            component :  index   // component可跳转组件（不是components），顶部导入
        },{
            path : '/login',
            name : 'login',
            meta:{title:'万彻博客-登录'},
            component :  login
        },{
            path : '/regist',
            name : 'regist',
            meta:{title:'万彻博客-注册'},
            component :  regist
        },{
            path : '/blogDetailPanel',
            name : 'blogDetailPanel',
            meta:{title:'博客详情'},
            component :  blogDetailPanel
        },{
            path : '/writeBlogPanel',
            name : 'writeBlogPanel',
            meta:{title:'博客车间'},
            component :  writeBlogPanel
        },{
            path : '/userCenterPanel',
            name : 'userCenterPanel',
            meta:{title:'个人中心'},
            component :  userCenterPanel
        },{
            path : '/basePanelSearchPanel',
            name : 'basePanelSearchPanel',
            meta:{title:'搜索博客'},
            component :  basePanelSearchPanel
        },{
            path : '/socketChatPanel',
            name : 'socketChatPanel',
            meta:{title:'聊天对话框'},
            component :  socketChatPanel
        },{
            path : '/searchUserPanel',
            name : 'searchUserPanel',
            meta:{title:'搜索用户'},
            component :  searchUserPanel
        },{
            path : '/blowUpPlanePanel',
            name : 'blowUpPlanePanel',
            meta:{title:'对战-炸飞机'},
            component :  blowUpPlanePanel
        },{
            path : '/minesweeperPanel',
            name : 'minesweeperPanel',
            meta:{title:'单机-扫雷'},
            component :  minesweeperPanel
        },{
            path : '/gameCenterPanel',
            name : 'gameCenterPanel',
            meta:{title:'娱乐中心'},
            component :  gameCenterPanel
        },{
            path : '/pokerDdzPanel',
            name : 'pokerDdzPanel',
            meta:{title:'双人斗地主'},
            component :  pokerDdzPanel
        },{
            path : '/sarsCov',
            name : 'sarsCovPanel',
            meta:{title:'疫情大数据'},
            component :  sarsCovPanel
        },
    ],
    mode: "history"
})