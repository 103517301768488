import { render, staticRenderFns } from "./blogDetailPanelComponent.vue?vue&type=template&id=164bbf74&scoped=true&"
import script from "./blogDetailPanelComponent.vue?vue&type=script&lang=js&"
export * from "./blogDetailPanelComponent.vue?vue&type=script&lang=js&"
import style0 from "./blogDetailPanelComponent.vue?vue&type=style&index=0&id=164bbf74&prod&scoped=true&lang=css&"
import style1 from "./blogDetailPanelComponent.vue?vue&type=style&index=1&id=164bbf74&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164bbf74",
  null
  
)

export default component.exports